import React from "react";
import {useThemeContext} from "../../../../utility/AppContextProvider/ThemeContextProvider";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";

interface AppLogoProps {
    color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({color}) => {
    const {theme} = useThemeContext();

    return (
        <Box
            sx={{
                height: {xs: 46, sm: 60},
                marginLeft: "10px",
                padding: 2.5,
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "start",
                "& svg": {
                    height: {xs: 40, sm: 45},
                },
            }}
            className="app-logo"
        >
            <Link to="/">
                <img src="/assets/images/logo.png" style={{maxHeight: "40px"}}/>
            </Link>
        </Box>
    );
};

export default AppLogo;
