import {baseService} from './baseService';
import {parseISO} from "date-fns";

export const companyService = {
    names,
    list,
    getCompany,
    addCompany,
    updateCompany,
    integrationStatus,
    listGroups,
    getRappi,
    saveRappi,
    updateRappi,
    getDeliveryMuch,
    saveDeliveryMuch,
    updateDeliveryMuch,
    getAnotaai,
    saveAnotaai,
    updateAnotaai,
    saveJuma,
    getJuma,
    updateJuma,
    getJumaCategories,
    getUber,
    saveUber,
    updateUber,
    getAiQFome,
    saveAiQFome,
    updateAiQFome,
    getRobinFood,
    saveRobinFood,
    updateRobinFood,
    getCardapioWeb,
    saveCardapioWeb,
    updateCardapioWeb,
    getBatataisFood,
    saveBatataisFood,
    updateBatataisFood,
};

function names() {
    return baseService.get(encodeURI(`api/company/names`));
}

function list(params) {
    const query = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    return baseService.get(encodeURI(`api/company?${query}`));
}

function getCompany(id) {
    return baseService.get(`api/company/${id}`)
}

function getAnotaai(id) {
    return baseService.get(`api/company/${id}/anotaai/credentials`)
}

function getRappi(id) {
    return baseService.get(`api/company/${id}/rappi/credentials`)
}

function saveRappi(id, rappi) {
    return baseService.post(`api/company/${id}/rappi/credentials`, rappi).then(() => rappi)
}

function updateRappi(id, rappi) {
    return baseService.put(`api/company/${id}/rappi/credentials`, rappi).then(() => rappi)
}

function getUber(id) {
    return baseService.get(`api/company/${id}/uber/credentials`)
}

function saveUber(id, uber) {
    return baseService.post(`api/company/${id}/uber/credentials`, uber).then(() => uber)
}

function updateUber(id, uber) {
    return baseService.put(`api/company/${id}/uber/credentials`, uber).then(() => uber)
}

function getCardapioWeb(id) {
    return baseService.get(`api/company/${id}/cardapioweb/credentials`)
}

function saveCardapioWeb(id, data) {
    return baseService.post(`api/company/${id}/cardapioweb/credentials`, data).then(() => data)
}

function updateCardapioWeb(id, data) {
    return baseService.put(`api/company/${id}/cardapioweb/credentials`, data).then(() => data)
}

function getBatataisFood(id) {
    return baseService.get(`api/company/${id}/batatais-food/credentials`)
}

function saveBatataisFood(id, data) {
    return baseService.post(`api/company/${id}/batatais-food/credentials`, data).then(() => data)
}

function updateBatataisFood(id, data) {
    return baseService.put(`api/company/${id}/batatais-food/credentials`, data).then(() => data)
}

function getDeliveryMuch(id) {
    return baseService.get(`api/company/${id}/delivery-much/credentials`)
}

function saveDeliveryMuch(id, deliveryMuch) {
    return baseService.post(`api/company/${id}/delivery-much/credentials`, deliveryMuch).then(() => deliveryMuch)
}

function updateDeliveryMuch(id, deliveryMuch) {
    return baseService.put(`api/company/${id}/delivery-much/credentials`, deliveryMuch).then(() => deliveryMuch)
}

function getAiQFome(id) {
    return baseService.get(`api/company/${id}/aiqfome/credentials`)
}

function saveAiQFome(id, aiqfome) {
    return baseService.post(`api/company/${id}/aiqfome/credentials`, aiqfome).then(() => aiqfome)
}

function updateAiQFome(id, aiqfome) {
    return baseService.put(`api/company/${id}/aiqfome/credentials`, aiqfome).then(() => aiqfome)
}

function getRobinFood(id) {
    return baseService.get(`api/company/${id}/robinfood/credentials`)
}

function saveRobinFood(id, robinfood) {
    return baseService.post(`api/company/${id}/robinfood/credentials`, robinfood).then(() => robinfood)
}

function updateRobinFood(id, robinfood) {
    return baseService.put(`api/company/${id}/robinfood/credentials`, robinfood).then(() => robinfood)
}

function getJuma(id) {
    return baseService.get(`api/company/${id}/juma/credentials`)
}

function getJumaCategories(id) {
    return baseService.get(`api/company/${id}/juma/categories`)
}

function saveJuma(id, deliveryMuch) {
    return baseService.post(`api/company/${id}/juma/credentials`, deliveryMuch).then(() => deliveryMuch)
}

function updateJuma(id, deliveryMuch) {
    return baseService.put(`api/company/${id}/juma/credentials`, deliveryMuch).then(() => deliveryMuch)
}

function saveAnotaai(id, anotaai) {
    return baseService.post(`api/company/${id}/anotaai/credentials`, anotaai).then(() => anotaai)
}

function updateAnotaai(id, anotaai) {
    return baseService.put(`api/company/${id}/anotaai/credentials`, anotaai).then(() => anotaai)
}

function addCompany(company) {
    return baseService.post('api/company', company).then(() => company);
}

function updateCompany(id, company) {
    return baseService.put(`api/company/${id}`, company).then(() => company);
}

function integrationStatus(id) {
    return baseService.get(`api/company/${id}/integration/status`);
}

function listGroups() {
    return baseService.get(encodeURI('api/company/groups'));
}
