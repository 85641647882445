import React, {useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import {selectCompany, selectModule, selectSeller, toggleNavCollapsed} from "../../../../../redux/actions";
import MenuIcon from "@mui/icons-material/Menu";
import {useDispatch, useSelector} from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppTooltip from "../../../AppTooltip";
import {alpha} from "@mui/material/styles";
import AppLogo from "../../components/AppLogo";
import UserInfo from "../../components/UserInfo";
import HorizontalNav from "../../components/HorizontalNav";
import {useSidebarContext} from "../../../../utility/AppContextProvider/SidebarContextProvider";
import {
    loadCompaniesState,
    loadCompanyState, loadSellerState,
    saveCompanyState,
    saveModuleState
} from "../../../../../redux/store/localStorage";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppsIcon from '@mui/icons-material/Apps';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import {Module} from "../../../../../types/models/Common";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {useNavigate} from "react-router-dom";
import {AppState} from "../../../../../redux/store";
import {useAuthUser} from "../../../../utility/AuthHooks";
import {Permission} from "../../../../../shared/constants/Permissions";

const AppHeader = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const module = useSelector<AppState, AppState["common"]>(
        ({ common }) => common
    ).module;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor} =
        useSidebarContext();
    const {isUserInRole} = useAuthUser();

    const handleChangeModule = (module: Module) => {
        dispatch(selectModule(module))
        saveModuleState(module)
        if (module === "company") {
            const company = loadCompanyState()
            if (!!company) {
                dispatch(selectCompany(company.id))
            }
            navigate("/company/request/list")
        }
        if (module === "support") {
            const seller = loadSellerState()
            if (!!seller) {
                dispatch(selectSeller(seller.id))
            }
            navigate("/support/request/list")
        }
    }

    const renderModuleIcon = () => {
        if (module === "company") return <RestaurantIcon/>;
        if (module === "support") return <SupportAgentIcon/>;
        return <AppsIcon/>;
    }

    return (
        <>
            <AppBar
                color="inherit"
                sx={{
                    boxShadow: "none",
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    backgroundColor: "background.paper",
                    width: {
                        xs: "100%",
                    },
                }}
                className="app-bar"
            >
                <Toolbar
                    sx={{
                        boxSizing: "border-box",
                        minHeight: {xs: 56, sm: 70},
                        px: {xs: 0},
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            mx: "auto",
                            px: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <Hidden lgUp>
                            <IconButton
                                sx={{
                                    marginRight: (theme) => theme.spacing(2),
                                    color: "text.secondary",
                                }}
                                edge="start"
                                className="menu-btn"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => dispatch(toggleNavCollapsed())}
                                size="large"
                            >
                                <MenuIcon
                                    sx={{
                                        width: 35,
                                        height: 35,
                                    }}
                                />
                            </IconButton>
                        </Hidden>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {isUserInRole(Permission.MODULE_SUPPORT) && <Box>
                                <Box
                                    onClick={handleClick}
                                    sx={{
                                        py: 3,
                                        px: 3,
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    className="user-info-view"
                                >
                                    <Box sx={{py: 0.5}}>
                                        {renderModuleIcon()}
                                    </Box>
                                </Box>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <MenuItem selected={module == "company"} onClick={() => handleChangeModule("company")}>
                                        <ListItemIcon>
                                            <RestaurantIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Visão Estabelecimento</ListItemText>
                                    </MenuItem>
                                    <MenuItem selected={module == "support"} onClick={() => handleChangeModule("support")}>
                                        <ListItemIcon>
                                            <SupportAgentIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Visão Suporte</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>}

                            <Box
                                sx={{
                                    mr: 2,
                                    "& .app-logo": {
                                        pl: 0,
                                    },
                                    "& .logo-text": {
                                        display: {xs: "none", sm: "block"},
                                    },
                                }}
                            >
                                <AppLogo/>
                            </Box>
                        </Box>
                        <Hidden lgDown>
                            <Box
                                sx={{
                                    ml: 5,
                                    "& .navbarNav": {
                                        display: "flex",
                                        padding: 0,
                                        mx: {xs: -4, lg: -5},
                                        marginRight: -16,
                                    },
                                    "& .navItem": {
                                        width: "auto",
                                        cursor: "pointer",
                                        px: {xs: 4, lg: 5},
                                        py: 1,
                                        borderRadius: 1,
                                        "&.active": {
                                            color: sidebarMenuSelectedTextColor,
                                            backgroundColor: alpha(sidebarMenuSelectedBgColor, 0.8),
                                            "& .navLinkIcon": {
                                                color: (theme) => theme.palette.secondary.main,
                                            },
                                        },
                                    },
                                    "& .navLinkIcon": {
                                        mr: 2.5,
                                        color: (theme) => theme.palette.common.white,
                                        fontSize: 20,
                                    },
                                }}
                            >
                                <HorizontalNav/>
                            </Box>
                        </Hidden>

                        <Box
                            sx={{
                                ml: 4,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >

                            <Box
                                sx={{
                                    ml: {sm: 4},
                                    mr: {xs: 4, sm: 0},
                                    "& .user-info-view": {
                                        p: 0,
                                    },
                                    "& .user-info": {
                                        display: "none",
                                    },
                                }}
                            >
                                <UserInfo/>
                            </Box>

                            <Hidden smUp>
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: -2,
                                        marginRight: -2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            px: 1.85,
                                        }}
                                    >
                                        <AppTooltip title="More">
                                            <IconButton
                                                sx={{
                                                    borderRadius: "50%",
                                                    width: 40,
                                                    height: 40,
                                                    color: (theme) => theme.palette.text.secondary,
                                                    backgroundColor: (theme) =>
                                                        theme.palette.background.default,
                                                    border: 1,
                                                    borderColor: "transparent",
                                                    "&:hover, &:focus": {
                                                        color: (theme) => theme.palette.text.primary,
                                                        backgroundColor: (theme) =>
                                                            alpha(theme.palette.background.default, 0.9),
                                                        borderColor: (theme) =>
                                                            alpha(theme.palette.text.secondary, 0.25),
                                                    },
                                                }}
                                                onClick={handleClick}
                                                size="large"
                                            >
                                                <MoreVertIcon/>
                                            </IconButton>
                                        </AppTooltip>
                                    </Box>
                                </Box>
                            </Hidden>

                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};
export default AppHeader;
