import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {useNavigate} from "react-router-dom";
import {loadCompanyState, saveCompanyState} from "../../../../../redux/store/localStorage";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {selectCompany} from "../../../../../redux/actions";
import authService from "../../../../../services/authService";
import {useFirebaseActions} from "../../../../services/auth/firebase/FirebaseAuthProvider";
import {useCompanyIntegrationStatus} from "../../../../../hooks/query/company.hooks";
import {CompanyIntegrationStatus} from "../../../../../types/models/Company";
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import {companyService} from "../../../../../services/companyService";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";


const CompanyModule = () => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState({name: "", id: ""});
    const [chooseCompany, setChooseCompany] = useState(false);
    const dispatch = useDispatch();
    const {setProfileData} = useFirebaseActions();
    const {data: companyStatus, refetch} = useCompanyIntegrationStatus(company.id)
    const [loading, setLoading] = useState(false);
    const [panelFilter, setPanelFilter] = useState("")

    const visible = !!panelFilter ? companies.filter(c => c.name.toLowerCase().includes(panelFilter.toLowerCase())) : companies

    useEffect(() => {
        const company = loadCompanyState();
        if (!company) {
            showCompanyDialog()
        } else {
            setCompany(company)
            onCompanySelected()
            dispatch(selectCompany(company.id));
        }

        const interval = setInterval(() => {
            if (!!company.id) refetch()
        }, 60 * 1000);
        return () => clearInterval(interval);

    }, []);

    const showCompanyDialog = () => {
        setLoading(true)
        setChooseCompany(true)
        companyService.names().then(resp => {
            setCompanies(resp.data)
        }).finally(() => setLoading(false))
    }

    const handleChooseCompany = async (company) => {
        saveCompanyState(company);
        setCompany(company);
        setChooseCompany(false);
        onCompanySelected()
        dispatch(selectCompany(company.id));
        navigate("/company/request/list");
    }

    const onCompanySelected = async () => {
        const loggedUser = await authService.loggedUser().then(resp => resp.data);
        const permissions = loggedUser.permissions;
        setProfileData({
            companyPermissions: loggedUser.permissions,
            isUserCompanyInRole: function(role: string) {
                return !!permissions && permissions.indexOf(role) >= 0
            },
        });
    }

    const getCompanyStatus = () => {
        switch (companyStatus) {
            case CompanyIntegrationStatus.ONLINE:
                return <CloudIcon fontSize="small" sx={{"&&": {color: "green"}}}/>
            case CompanyIntegrationStatus.OFFLINE:
                return <CloudOffIcon fontSize="small" sx={{"&&": {color: "red"}}}/>
            case CompanyIntegrationStatus.UNKNOW:
                return <CloudQueueIcon fontSize="small" sx={{"&&": {color: "gray"}}}/>
        }
    }

    return (
        <>
            <Box sx={{p: 3}}>
                <Chip onClick={showCompanyDialog}
                      icon={getCompanyStatus()} label={company.name} variant="outlined"/>
            </Box>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={chooseCompany}
                onClose={() => setChooseCompany(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    Escolha um estabelecimento
                    <Grid container item sm={12} spacing={4} mt={3}>
                        <TextField
                            size="small"
                            id="filter"
                            label="Digite o nome do estabelecimento"
                            value={panelFilter}
                            variant="outlined"
                            fullWidth
                            onChange={(e) =>
                                setPanelFilter(e.target.value)
                            }
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{height: "500px"}}>
                    <Grid container item sm={12} spacing={4} mt={3}>
                        {loading && <CircularProgress/>}
                        {!loading && visible?.map((c, i) => <Button
                            key={`company-choose-${i}`}
                            onClick={() => handleChooseCompany(c)}
                            className="w-full m-1"
                            type="button"
                            variant="contained"
                            color="primary">{c.name}
                        </Button>)}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CompanyModule;
