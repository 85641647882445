import {AuthUser} from "../../types/models/AuthUser";
import {OptionsObject} from "notistack";

export const authRole = {
    admin: ["admin"],
    user: ["user", "admin"],
};

    export enum RoutePermittedRole {
    Admin = "admin",
    User = "user",
}

export const defaultUser: AuthUser = {
    uid: "john-alex",
    displayName: "John Alex",
    email: "demo@example.com",
    token: "access-token",
    role: "user",
    photoURL: "/assets/images/avatar/A11.jpg",
};
export const initialUrl = "/"; // this url will open after login

export const successOptions: OptionsObject = {
    variant: 'success',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    }
}

export const errorOptions: OptionsObject = {
    variant: 'error',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    }
}

export enum RoutePermittedRole {
    SUPPORT_VIEW_USER = "SUPPORT_VIEW_USER",
    COMPANY_VIEW_USER = "COMPANY_VIEW_USER",
    MARKETPLACE_ANOTAAI = "MARKETPLACE_ANOTAAI",
    MARKETPLACE_ROBINFOOD = "MARKETPLACE_ROBINFOOD",
    MARKETPLACE = "MARKETPLACE",
    ORDER_PANEL = "ORDER_PANEL",
}
