import * as yup from "yup";

export interface Company {
    uberStoreId?: string;
    id: number;
    uuid: string;
    name: string;
    register: string;
    erp: string;
    paused: boolean;
    totvsData?: TotvsData;
    anotaAiToken?: string;
    companyMarketplaces?: CompanyMarketplaces;
    companyLogistics?: CompanyLogistics;
}

export interface CompanyMarketplaces {
    rappiStoreId?: string;
    rappiEnabled?: boolean;
    deliverymuchStoreId?: string;
    deliverymuchEnabled?: boolean;
    anotaaiStoreId?: string;
    anotaaiEnabled?: boolean;
    uberStoreId?: string;
    uberEnabled?: boolean;
    aiqfomeStoreId?: string;
    aiqfomeEnabled?: boolean;
    robinfoodStoreId?: string;
    robinfoodEnabled?: boolean;
    cardapiowebStoreId?: string;
    cardapiowebEnabled?: boolean;
    batataisfoodStoreId?: string;
    batataisfoodEnabled?: boolean;
}

export interface CompanyLogistics {
    jumaStoreId?: string;
    jumaEnabled?: boolean;
}

export interface AnotaaiCredentials {
    idClient: string;
    anotaAiToken?: string;
}

export interface RappiCredentials {
    storeId: string;
    audience: string;
    clientId: string;
    clientSecret: string;
    grantType: string;
    idClient: string;
}

export interface UberCredentials {
    storeId: string;
    idClient: string;
}

export interface CardapioWebCredentials {
    storeId: string;
    idClient: string;
}

export interface BatataisFoodCredentials {
    storeId: string;
    idClient: string;
}

export interface DeliveryMuchCredentials {
    username: string;
    password: string;
    idClient: string;
}

export interface AiQFomeCredentials {
    storeId: string;
    username: string;
    password: string;
    idClient: string;
}

export interface RobinFoodCredentials {
    storeId: string;
    username: string;
    password: string;
    idClient: string;
}

export interface JumaCredentials {
    clientId: string;
    clientSecret: string;
    idClient: string;
    categoryId?: string;
}

export interface JumaCategory {
    id: string;
    name: string;
}

export interface TotvsData {
    code: string;
    uuid: string;
    fractionCalcType: string;
}

export enum CompanyIntegrationStatus {
    ONLINE, OFFLINE, UNKNOW
}

export const emptyCompany = (): Company => {
    const id = crypto.randomUUID()
    return {
        id: undefined,
        uuid: id,
        name: '',
        register: '',
        erp: 'totvs', // TODO COlocar regra em tela
        paused: true,
        totvsData: {
            code: "",
            uuid: id,
            fractionCalcType: "AVERAGE"
        },
        anotaAiToken: "",
        uberStoreId: ""
    }
};

export const emptyRappi = (idClient: string): RappiCredentials => {
    return {
        storeId: "",
        audience: "",
        clientId: "",
        clientSecret: "",
        grantType: "",
        idClient: idClient
    }
}

export const emptyUber = (idClient: string): UberCredentials => {
    return {
        storeId: "",
        idClient: idClient
    }
}

export const emptyCardapioWeb = (idClient: string): CardapioWebCredentials => {
    return {
        storeId: "",
        idClient: idClient
    }
}

export const emptyBatataisFood = (idClient: string): BatataisFoodCredentials => {
    return {
        storeId: "",
        idClient: idClient
    }
}


export const emptyDeliveryMuch = (idClient: string): DeliveryMuchCredentials => {
    return {
        username: "",
        password: "",
        idClient: idClient
    }
}

export const emptyRobinFood = (idClient: string): RobinFoodCredentials => {
    return {
        storeId: "",
        username: "",
        password: "",
        idClient: idClient
    }
}

export const emptyAiQFome = (idClient: string): AiQFomeCredentials => {
    return {
        username: "",
        password: "",
        idClient: idClient,
        storeId: ""
    }
}


export const emptyJuma = (idClient: string): JumaCredentials => {
    return {
        clientId: "",
        clientSecret: "",
        idClient: idClient,
        categoryId: ""
    }
}

export const emptyAnotaai = (idClient: string): AnotaaiCredentials => {
    return {
        idClient: idClient,
        anotaAiToken: ""
    }
}

