// action strings
import {Module} from "../models/Common";

export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';

export const SELECT_SELLER = 'SELECT_SELLER';
export const SELECT_MODULE = 'SELECT_MODULE';

export const DESELECT_DATA = 'DESELECT_DATA';

export interface FetchStartAction {
  type: typeof FETCH_START;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  error: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
}

export interface ToggleDrawerAction {
  type: typeof TOGGLE_APP_DRAWER;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}

export interface SelectCompanyAction {
  type: typeof SELECT_COMPANY;
  id: string;
}

export interface DeselectCompanyAction {
  type: typeof DESELECT_COMPANY;
}

export interface DeselectData {
  type: typeof DESELECT_DATA;
}

export interface SelectModuleAction {
  type: typeof SELECT_MODULE;
  module: Module;
}

export interface SelectSellerAction {
  type: typeof SELECT_SELLER;
  id: string;
}

export type CommonActionTypes =
  | SelectCompanyAction
  | DeselectCompanyAction
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | ShowMessageAction
  | ToggleDrawerAction
  | SelectModuleAction
  | SelectSellerAction
  | DeselectData
  | HideMessageAction;
