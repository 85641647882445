import axios from 'axios'
import firebase from 'firebase/compat/app';
import {loadCompanyState, loadModuleState, loadSellerState} from "../redux/store/localStorage";

let path = '';

if (process.env.NODE_ENV === 'development') {
    path = 'http://localhost:8090';
} else {
    // path = window.location.protocol + '//' + window.location.host;
    path = 'https://api.ezdeal.com.br';
}

const baseService = axios.create({
    baseURL: path,
});

baseService.interceptors.request.use(async function (config) {

    const module = loadModuleState();
    const company = loadCompanyState();
    const seller = loadSellerState();

    if (module === "support") {
        config.headers.common["seller"] = seller?.id || "";
        config.headers.common["company"] = "";
    } else {
        config.headers.common["company"] = company?.id || "";
        config.headers.common["seller"] = "";
    }

    if (company && config.filterCompany && config.method === "get") {
        config.params = {...config.params, company: company.uuid}
    }

    const user = firebase.auth().currentUser;

    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');

    const token = await user?.getIdToken()
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    //config.headers['X-XSRF-TOKEN'] = csrfToken

    return config;
}, function (error) {
    return Promise.reject(error);
});

export {baseService, path};
