export enum ThemeStyle {
  MODERN = "modern",
  STANDARD = "standard",
}

export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 16,
}

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export enum LayoutType {
  FULL_WIDTH = "full-width",
  BOXED = "boxed",
  FRAMED = "framed",
}

export enum MenuStyle {
  DEFAULT = "default",
  STANDARD = "standard",
  ROUNDED = "rounded",
  ROUNDED_REVERSE = "rounded-reverse",
  CURVED_MENU = "curved-menu",
}

export enum LayoutDirection {
  RTL = "rtl",
  LTR = "ltr",
}

export enum NavStyle {
  DEFAULT = "default",
  BIT_BUCKET = "bit_bucket",
  STANDARD = "standard",
  DRAWER = "drawer",
  MINI = "mini",
  MINI_SIDEBAR_TOGGLE = "mini-sidebar-toggle",
  HEADER_USER = "user-header",
  HEADER_USER_MINI = "user-mini-header",
  H_DEFAULT = "h-default",
  HOR_HEADER_FIXED = "hor-header-fixed",
  HOR_DARK_LAYOUT = "hor-dark-layout",
}

export enum FooterType {
  FIXED = "fixed",
  FLUID = "fluid",
}

export enum HeaderType {
  FLUID = "fluid",
  FIXED = "fixed",
}

export enum RouteTransition {
  NONE = "none",
  SLIDE_LEFT = "slideLeft",
  SLIDE_RIGHT = "slideRight",
  SLIDE_UP = "slideUp",
  SLIDE_DOWN = "slideDown",
}

export enum Fonts {
  LIGHT = "300",
  REGULAR = "400",
  MEDIUM = "500",
  SEMI_BOLD = "600",
  BOLD = "700",
}

export enum AuthType {
  FIREBASE = "firebase",
  AWS_COGNITO = "aws_cognito",
  AUTH0 = "auth0",
  JWT_AUTH = "jwt_auth",
}

export enum AppFilterMenuInput {
  TEXT,
  SELECT,
  DATE,
  MONTH,
  DATETIME
}

export enum AppEventType {
  CHECK_IN = "Entrada",
  CHECK_OUT = "Saída"
}

export enum AppAlarmType {
  CHECK_IN_ABSENCE= "Entrada",
  CHECK_OUT_ABSENCE = "Saída",
  LUNCH_EXCEEDED = "Excesso de intervalo"
}

export enum FirebaseMessages {
  "auth/wrong-password"= "Usuário/Senha inválido!",
  "auth/expired-action-code"= "Código expirado, envie outra requisição!",
  "auth/invalid-action-code" = "Código de validação inválido!"
}

export enum AppStateAction {
  CREATED = "Criar",
  CONFIRMED = "Confirmar",
  IN_PRODUCTION = "Produzir",
  READY_FOR_PICKUP = "Disponibilizar",
  READY_FOR_DISPATCH = "Disponibilizar",
  DISPATCHING = "Entregar",
  CONCLUDED = "Concluir",
  CANCELLED = "Cancelar"
}

export enum AppState {
  CREATED = "Criado",
  CONFIRMED = "Confirmado",
  IN_PRODUCTION = "Em Produção",
  READY_FOR_PICKUP = "Disponível",
  READY_FOR_DISPATCH = "Disponível",
  DISPATCHING = "Entregando",
  CONCLUDED = "Concluído",
  CANCELLED = "Cancelado"
}

export enum AppConfirmState {
  CREATED = "a criação do pedido?",
  CONFIRMED = "o aceite do pedido?",
  IN_PRODUCTION = "a produção do pedido?",
  READY_FOR_PICKUP = "a disponibilização do pedido?",
  READY_FOR_DISPATCH = "a disponibilização do pedido?",
  DISPATCHED = "a entrega do pedido?",
  CONCLUDED = "a conclusão do pedido?",
  CANCELLED = "o cancelamento do pedido?"
}
