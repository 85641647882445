import {
  CommonActionTypes,
  DESELECT_COMPANY, DESELECT_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SELECT_COMPANY,
  SELECT_MODULE,
  SELECT_SELLER,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
} from "../../types/actions/Common.action";
import {Module} from "../../types/models/Common";

export const fetchStart = (): CommonActionTypes => ({ type: FETCH_START });

export const fetchSuccess = (): CommonActionTypes => ({ type: FETCH_SUCCESS });

export const fetchError = (error: string): CommonActionTypes => ({
  type: FETCH_ERROR,
  error,
});

export const showMessage = (message: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  message,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonActionTypes => ({ type: HIDE_MESSAGE });

export const selectCompany = (id: string): CommonActionTypes => ({
  type: SELECT_COMPANY,
  id
});

export const deselectCompany = (): CommonActionTypes => ({ type: DESELECT_COMPANY });

export const deselectData = (): CommonActionTypes => ({ type: DESELECT_DATA });

export const selectModule = (module: Module): CommonActionTypes => ({
  type: SELECT_MODULE,
  module
});

export const selectSeller = (id: string): CommonActionTypes => ({
  type: SELECT_SELLER,
  id
});