import {useMutation, useQuery, useQueryClient} from "react-query";
import {companyService} from "../../services/companyService";
import {errorOptions, successOptions} from "../../shared/constants/AppConst";
import {useSnackbar} from "notistack";
import {Name} from "../../types/models/Name";
import {
    AiQFomeCredentials,
    AnotaaiCredentials,
    Company,
    CompanyIntegrationStatus,
    DeliveryMuchCredentials,
    RappiCredentials, UberCredentials
} from "../../types/models/Company";
import {DataList} from "../../types/models/DataList";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/store";
import {SellerGroup} from "../../types/models/SellerGroup";
import {sellerService} from "../../services/sellerService";
import {Rappi} from "../../types/models/Rappi";
import AiQFome from "../../pages/support/company/CompanyData/AiQFome";

export const useCompaniesNames = (enabled: boolean, seller?: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const loadNames = async (): Promise<Name[]> => {

        return companyService.names()
            .then((resp) => resp.data)
            .catch((error) => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions)
            });
    };

    return useQuery(["company-names", seller], () => loadNames(), {keepPreviousData: false, enabled});
};

export const useCompanyIntegrationStatus = (id: string) => {
    const loadStatus = async (): Promise<CompanyIntegrationStatus> => {

        return companyService.integrationStatus(id)
            .then((resp) => CompanyIntegrationStatus.ONLINE)
            .catch((error) => {
                if (error.response.status === 503) {
                    return CompanyIntegrationStatus.OFFLINE
                }
                return CompanyIntegrationStatus.UNKNOW
            });
    };

    return useQuery(["company-status", id], () => loadStatus(), {keepPreviousData: false, enabled: !!id});
};

export const useCompanies = (params) => {
    const {enqueueSnackbar} = useSnackbar();

    const loadCompanies = async (): Promise<DataList<Company>> => {

        return companyService.list(params)
            .then((resp) => resp.data)
            .catch((error) => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions)
            });
    };

    return useQuery(["company-list", params], () => loadCompanies(), {keepPreviousData: false});
};

export const useCompany = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findCompany = async (id: string): Promise<Company> => {
        return companyService.getCompany(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["company", id], () => findCompany(id), {keepPreviousData: false, enabled: !!id});
};

export const useAnotaai = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findAnotaai = async (id: string): Promise<AnotaaiCredentials> => {
        return companyService.getAnotaai(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["anotaai", id], () => findAnotaai(id), {keepPreviousData: false, enabled: !!id});
};

export const useSaveAnotaai = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (anotaai: AnotaaiCredentials) =>
            companyService.saveAnotaai(anotaai.idClient, anotaai),
        onSuccess: (anotaai: AnotaaiCredentials) => {
            queryClient.setQueryData(['anotaai', anotaai.idClient], anotaai);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUpdateAnotaai = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (anotaai: AnotaaiCredentials) =>
            companyService.updateAnotaai(anotaai.idClient, anotaai),
        onSuccess: (anotaai: AnotaaiCredentials) => {
            queryClient.setQueryData(['rappi', anotaai.idClient], anotaai);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useRappi = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findRappi = async (id: string): Promise<RappiCredentials> => {
        return companyService.getRappi(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["rappi", id], () => findRappi(id), {keepPreviousData: false, enabled: !!id});
};

export const useSaveRappi = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (rappi: RappiCredentials) =>
            companyService.saveRappi(rappi.idClient, rappi),
        onSuccess: (rappi: RappiCredentials) => {
            queryClient.setQueryData(['rappi', rappi.idClient], rappi);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUpdateRappi = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (rappi: RappiCredentials) =>
            companyService.updateRappi(rappi.idClient, rappi),
        onSuccess: (rappi: RappiCredentials) => {
            queryClient.setQueryData(['rappi', rappi.idClient], rappi);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUber = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findUber = async (id: string): Promise<UberCredentials> => {
        return companyService.getUber(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["uber", id], () => findUber(id), {keepPreviousData: false, enabled: !!id});
};

export const useSaveUber = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (uber: UberCredentials) =>
            companyService.saveUber(uber.idClient, uber),
        onSuccess: (uber: UberCredentials) => {
            queryClient.setQueryData(['uber', uber.idClient], uber);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUpdateUber = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (uber: UberCredentials) =>
            companyService.updateUber(uber.idClient, uber),
        onSuccess: (uber: UberCredentials) => {
            queryClient.setQueryData(['uber', uber.idClient], uber);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useSaveDeliveryMuch = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (deliveryMuch: DeliveryMuchCredentials) =>
            companyService.saveDeliveryMuch(deliveryMuch.idClient, deliveryMuch),
        onSuccess: (deliveryMuch: DeliveryMuchCredentials) => {
            queryClient.setQueryData(['delivery-much', deliveryMuch.idClient], deliveryMuch);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUpdateDeliveryMuch = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (deliveryMuch: DeliveryMuchCredentials) =>
            companyService.updateDeliveryMuch(deliveryMuch.idClient, deliveryMuch),
        onSuccess: (deliveryMuch: DeliveryMuchCredentials) => {
            queryClient.setQueryData(['delivery-much', deliveryMuch.idClient], deliveryMuch);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useDeliveryMuch = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findDeliveryMuch = async (id: string): Promise<DeliveryMuchCredentials> => {
        return companyService.getDeliveryMuch(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["delivery-much", id], () => findDeliveryMuch(id), {keepPreviousData: false, enabled: !!id});
};

export const useSaveAiQFome = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (aiqfome: AiQFomeCredentials) =>
            companyService.saveAiQFome(aiqfome.idClient, aiqfome),
        onSuccess: (aiqfome: AiQFomeCredentials) => {
            queryClient.setQueryData(['aiqfome', aiqfome.idClient], aiqfome);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useUpdateAiQFome = () => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (aiqfome: AiQFomeCredentials) =>
            companyService.updateAiQFome(aiqfome.idClient, aiqfome),
        onSuccess: (aiqfome: AiQFomeCredentials) => {
            queryClient.setQueryData(['aiqfome', aiqfome.idClient], aiqfome);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useAiQFome = (id: string) => {
    const {enqueueSnackbar} = useSnackbar();

    const findAiQFome = async (id: string): Promise<AiQFomeCredentials> => {
        return companyService.getAiQFome(id)
            .then((resp) => resp.data)
            .catch(() => {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            });
    };

    return useQuery(["aiqfome", id], () => findAiQFome(id), {keepPreviousData: false, enabled: !!id});
};

export const useUpdateCompany = () => {
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: (company: Company) =>
            companyService.updateCompany(company.id, company),
        onSuccess: (company: Company) => {
            // ✅ update detail view directly
            queryClient.setQueryData(['company', company.id], company);
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
            navigate('/support/company/list');
        },
        onError: (error) => {
            if (error['response'] && error['response'].status === 400) {
                enqueueSnackbar(error['response'].data, errorOptions);
            } else {
                enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
            }

        }
    })
};

export const useAddCompany = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: (company: Company) =>
            companyService.addCompany(company),
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: (company: Company) => {
            enqueueSnackbar("Dados salvos com sucesso!", successOptions);
            navigate('/support/company/list');
        },
        onError: () => {
            enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
        }
    })
};

export const useCompanyGroups = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {idCompanySelected} = useSelector<AppState, AppState["common"]>(
        ({common}) => common
    );
    const list = async (): Promise<SellerGroup[]> => {
        return companyService.listGroups()
            .then((resp) => resp.data)
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar("Ocorreu um erro inesperado", errorOptions);
                }
            });
    };

    return useQuery(["seller-group", idCompanySelected], () => list(), { keepPreviousData: false});
};