import React, {createContext, ReactNode, useContext, useEffect, useState,} from "react";
import {useDispatch} from "react-redux";
import {auth, facebookAuthProvider, githubAuthProvider, googleAuthProvider, twitterAuthProvider,} from "./firebase";
import {AuthUser} from "../../../../types/models/AuthUser";
import {
  deselectCompany, deselectData,
  fetchError,
  fetchStart,
  fetchSuccess,
  selectCompany, selectModule,
  selectSeller,
} from "../../../../redux/actions";
import authService from "../../../../services/authService";
import {EmailAuthProvider} from 'firebase/auth';
import {FirebaseMessages} from "../../../../shared/constants/AppEnums";
import {baseService} from "../../../../services/baseService";
import {
  clearCompaniesState, clearModuleState, clearSellerState,
  saveCompanyState,
  saveModuleState,
  saveSellerState
} from "../../../../redux/store/localStorage";
import {LoggedUser} from "../../../../types/models/LoggedUser";
import {useNavigate} from "react-router-dom";
import {User} from "../../../../types/models/User";
import {useAddUser} from "../../../../hooks/query/user.hooks";
import {userService} from "../../../../services/userService";

interface FirebaseContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface FirebaseContextProfileProps {
  loggedUser?: any;
  permissions?: string[];
  companyPermissions?: string[];
  company?: string;
  isUserInRole?: (role: string) => boolean;
  isUserCompanyInRole?: (role: string) => boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface ChangePasswordProps {
  password: string;
}

interface ResetPasswordProps {
  email: string;
}

interface ConfirmCodeProps {
  code: string;
}

interface ConfirmPasswordResetProps {
  code: string;
  password: string;
}

interface FirebaseActionsProps {
  createUserWithEmailAndPassword: (data: User | SignUpProps) => void;
  signInWithEmailAndPassword: (data: SignInProps) => void;
  signInWithPopup: (type: string) => void;
  logout: () => void;
  sendPasswordResetEmail: (data: ResetPasswordProps) => void;
  verifyPasswordResetCode: (data: ConfirmCodeProps) => Promise<any>,
  confirmPasswordReset: (data: ConfirmPasswordResetProps) => void;
  changePassword: (data: ChangePasswordProps) => Promise<any>;
  setProfileData: (data: FirebaseContextProfileProps) => void;
}

const FirebaseContext = createContext<FirebaseContextProps & FirebaseContextProfileProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  permissions: [],
  companyPermissions: [],
});
const FirebaseActionsContext = createContext<FirebaseActionsProps>({
  createUserWithEmailAndPassword: () => {},
  signInWithEmailAndPassword: () => {},
  signInWithPopup: () => {},
  logout: () => {},
  sendPasswordResetEmail: () => {},
  verifyPasswordResetCode: () => {return Promise.resolve()},
  confirmPasswordReset: () => {},
  changePassword: ()=> {return Promise.resolve()},
  setProfileData: ()=> {}
});

export const useFirebase = () => useContext(FirebaseContext);

export const useFirebaseActions = () => useContext(FirebaseActionsContext);

interface FirebaseAuthProviderProps {
  children: ReactNode;
}

const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setFirebaseData] = useState<FirebaseContextProps & FirebaseContextProfileProps>({
    user: undefined,
    isLoading: true,
    isAuthenticated: false,
    permissions: [],
    isUserInRole: () => false
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    baseService.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    });

  }, [])

  useEffect(() => {
    const getAuthUser = auth.onAuthStateChanged(
      (user) => {
        onLoggedUser(user);
      },
      () => {
        setFirebaseData({
          user: firebaseData.user,
          isLoading: false,
          isAuthenticated: false,
        });
      },
      () => {
        setFirebaseData({
          user: firebaseData.user,
          isLoading: false,
          isAuthenticated: true,
        });
      }
    );

    return () => {
      getAuthUser();
    };
  }, [firebaseData.user]);

  const onLoggedUser = async (user: AuthUser) => {
    if (!!user && firebaseData.isAuthenticated) {

      dispatch(fetchStart());

      const loggedUser: LoggedUser = await authService.loggedUser().then(resp => resp.data);

      if (!!loggedUser.seller) {
        saveSellerState(loggedUser.seller)
        saveModuleState("support")
        dispatch(selectModule("support"))
        dispatch(selectSeller(loggedUser.seller.id))
        console.log("seller")
      } else if (!!loggedUser.company) {
        saveCompanyState(loggedUser.company)
        saveModuleState("company")
        dispatch(selectModule("company"))
        console.log("aqui")
        dispatch(selectCompany(loggedUser.company.id))
      }

      const permissions = loggedUser.permissions;

      setFirebaseData({
        user: user as AuthUser,
        isAuthenticated: Boolean(user),
        isLoading: false,
        permissions: loggedUser.permissions,
        isUserInRole: function(role: string) {
          return !!permissions && permissions.indexOf(role) >= 0
        }
      });

      dispatch(fetchSuccess());

      return loggedUser.permissions

    } else {
      setFirebaseData({
        user: user as AuthUser,
        isAuthenticated: Boolean(user),
        isLoading: false,
        permissions: []
      });
    }
  }

  const getProvider = (providerName: string) => {
    switch (providerName) {
      case "google": {
        return googleAuthProvider;
      }
      case "facebook": {
        return facebookAuthProvider;
      }
      case "twitter": {
        return twitterAuthProvider;
      }
      case "github": {
        return githubAuthProvider;
      }
      default:
        return googleAuthProvider;
    }
  };

  const signInWithPopup = async (providerName: string) => {
    dispatch(fetchStart());
    try {
      const { user } = await auth.signInWithPopup(getProvider(providerName));
      setFirebaseData({
        user: user as AuthUser,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError(message as string));
    }
  };

  const signInWithEmailAndPassword = async ({
    email,
    password,
  }: SignInProps) => {
    dispatch(fetchStart());
    try {
      await auth.signInWithEmailAndPassword(email, password);
      dispatch(fetchSuccess());
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError( FirebaseMessages[error.code] || error.message as string));
    }
  };
  const createUserWithEmailAndPassword = async ({
    name,
    email,
    password,
  }: SignUpProps) => {
    dispatch(fetchStart());
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await auth!.currentUser!.sendEmailVerification({
        url: window.location.href,
        handleCodeInApp: true,
      });
      await auth!.currentUser!.updateProfile({
        displayName: name,
      });
      userService.addUser({
        name,
        email
      }).then(resp => {
        setFirebaseData({
          user: { ...user, displayName: name } as AuthUser,
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch(fetchSuccess());
      })

    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError(message as string));
    }
  };

  const logout = async () => {
    clearCompaniesState();
    clearSellerState();
    clearModuleState();
    dispatch(deselectData())
    setFirebaseData({ ...firebaseData, isLoading: true });
    try {
      await auth.signOut();
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  const changePassword = async ({password}: ChangePasswordProps) => {
    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
      return auth.currentUser.reauthenticateWithCredential(credential).then(() => {
        auth.currentUser.updatePassword(password)
      })
    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isLoading: false,
      });
      dispatch(fetchError(message as string));
    }
  };

  const sendPasswordResetEmail = async ({email} : ResetPasswordProps) => {

    await auth.sendPasswordResetEmail(email).catch((error) => {
      const errorMessage = error.message;
      dispatch(fetchError(errorMessage as string));
    });
  }

  const confirmPasswordReset = async ({code, password} : ConfirmPasswordResetProps) => {

    await auth.confirmPasswordReset(code, password).catch((error) => {
      const errorMessage = error.message;
      dispatch(fetchError(errorMessage as string));
    });
  }

  const verifyPasswordResetCode = async ({code} : ConfirmCodeProps) => {
    return auth.verifyPasswordResetCode(code)
  }

  const setProfileData = (data: FirebaseContextProfileProps) => {
    setFirebaseData({
      ...firebaseData,
      ...data
    });
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <FirebaseActionsContext.Provider
        value={{
          signInWithEmailAndPassword,
          createUserWithEmailAndPassword,
          signInWithPopup,
          logout,
          changePassword,
          sendPasswordResetEmail,
          verifyPasswordResetCode,
          confirmPasswordReset,
          setProfileData
        }}
      >
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  );
};
export default FirebaseAuthProvider;
