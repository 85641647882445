import React from "react";

const EmailMode = React.lazy(() => import("./EmailMode"));
const Signin = React.lazy(() => import("./Signin"));
const Signup = React.lazy(() => import("./Signup"));
const ForgotPassword = React.lazy(() => import("./ForgetPassword"));
const ConfirmResetPassword = React.lazy(() => import("./ConfirmResetPassword"));
const ConfirmSignupAwsCognito = React.lazy(
  () => import("./ConfirmSignupAwsCognito")
);
const ResetPasswordAwsCognito = React.lazy(
  () => import("./ResetPasswordAwsCognito")
);

export const authRouteConfig = [
  {
    path: "/mode",
    element: <EmailMode />,
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/confirm-signup",
    element: <ConfirmSignupAwsCognito />,
  },
  {
    path: "/reset-password",
    element: <ConfirmResetPassword />,
  },
];
