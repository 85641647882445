import {baseService} from './baseService';
import {parseISO} from "date-fns";

export const userService = {
    getUserCompanies,
    loggedUser,
    list,
    getUser,
    addUser,
    updateUser,
    updateUserGroup
};

function list(params) {
    const query = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    return baseService.get(encodeURI(`api/user?${query}`));
}

function getUser(id) {
    return baseService.get(`api/user/${id}`)
}

function getUserCompanies() {
    return baseService.get("api/user/companies", {
        filterCompany: true
    })
}

function loggedUser() {
    return baseService.get('api/user/loggedUser');
}

function addUser(user) {
    return baseService.post('api/user', user).then(() => user);
}

function updateUser(id, user) {
    return baseService.put(`api/user/${id}`, user).then(() => user);
}

function updateUserGroup(id, user) {
    return baseService.patch(`api/user/${id}`, user).then(() => user);
}