import React from "react";
import clsx from "clsx";
import AppContentView from "@crema/core/AppContentView";
import AppFixedFooter from "./AppFixedFooter";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import HorHeaderFixedWrapper from "./HorHeaderFixedWrapper";
import MainContent from "./MainContent";
import { LayoutType } from "../../../../shared/constants/AppEnums";
import HorHeaderFixedContainer from "./HorHeaderFixedContainer";
import {useSelector} from "react-redux";
import {AppState} from "../../../../redux/store";
import {loadCompanyState} from "../../../../redux/store/localStorage";
import {useAuthUser} from "../../../utility/AuthHooks";
import Error403 from "../../../../pages/errorPages/Error403";

const HorHeaderFixed = () => {
  const { footer, layoutType, footerType } = useLayoutContext();
  const {idCompanySelected, idSellerSelected, module} = useSelector<AppState, AppState["common"]>(
        ({ common }) => common
  );
  const {isUserInRole, isAuthenticated, permissions} = useAuthUser()

    console.log("content", idSellerSelected, idSellerSelected, module)

  return (
    <HorHeaderFixedContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <HorHeaderFixedWrapper
        className={clsx("horHeaderFixedWrapper", {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
        })}
      >
        <AppSidebar />

          {isAuthenticated && !!permissions && <MainContent>
            {!!isUserInRole && <AppHeader />}
            {((!!idCompanySelected && module === "company") || (!!idSellerSelected && module === "support")) && !!isUserInRole && <AppContentView />}
          <AppFixedFooter />
        </MainContent>}

          {isAuthenticated && !permissions && <MainContent>
              <Error403/>
              <AppFixedFooter />
          </MainContent>}

      </HorHeaderFixedWrapper>
    </HorHeaderFixedContainer>
  );
};

export default HorHeaderFixed;
