import {initialUrl} from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import React from "react";
import {errorPagesConfigs} from "./errorPages";
import {authRouteConfig} from "./auth";
import {suportRequestPagesConfigs} from "./support";
import {commonPagesConfigs} from "./common";
import {companyPagesConfigs} from "./company";

const authorizedStructure = (home: React.ReactElement) =>  {

    return {
        fallbackPath: "/signin",
            unAuthorizedComponent: <Error403/>,
        routes: [
            ...companyPagesConfigs,
            ...commonPagesConfigs,
            ...suportRequestPagesConfigs,
            {
                path: [
                    "/",
                ],
                element: home,
            },
        ],

    }
};

const unAuthorizedStructure = {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
};

const anonymousStructure = {
    routes: errorPagesConfigs,
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
