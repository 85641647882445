import React from "react";

const RequestList = React.lazy(() => import("./request/RequestList"));
const RequestPage = React.lazy(() => import("./request/RequestPage"));
const UserCompanyList = React.lazy(() => import("./user/UserCompanyList"));
const UserCompanyData = React.lazy(() => import("./user/UserCompanyData"));
const CompanyDashBoard = React.lazy(() => import("./dashboard/CompanyDashBoard"));
const OrderPanel = React.lazy(() => import("./panel"));
export const companyPagesConfigs = [
    {
        path: [
            "/company/dashboard",
        ],
        element: <CompanyDashBoard/>,
    },
    {
        path: [
            "/company/request/panel",
        ],
        element: <OrderPanel/>,
    },
    {
        path: [
            "/company/request/list",
        ],
        element: <RequestList/>,
    },
    {
        path: [
            "/company/request/:id",
            "/company/request"
        ],
        element: <RequestPage/>,
    },
    {
        path: [
            "/company/user/list",
        ],
        element: <UserCompanyList/>,
    },
    {
        path: [
            "/company/user/:id",
            "/company/user"
        ],
        element: <UserCompanyData/>,
    }
];