import React from "react";

const AnotaAiCatalog = React.lazy(() => import("./AnotaAiCatalog"));
const RobinFoodCatalog = React.lazy(() => import("./RobinFoodCatalog"));
const CatalogList = React.lazy(() => import("./CatalogList"));
export const commonPagesConfigs = [
    {
        path: [
            "/support/anotaai",
            "/company/anotaai",
        ],
        element: <AnotaAiCatalog/>,
    },
    {
        path: [
            "/support/robinfood",
            "/company/robinfood",
        ],
        element: <RobinFoodCatalog/>,
    },
    {
        path: [
            "/company/catalog/:id",
            "/company/catalog",
            "/support/catalog/:id",
            "/support/catalog"
        ],
        element: <CatalogList/>,
    },
];