import React, {useEffect, useState} from "react";
import HorizontalGroup from "./HorizontalGroup";
import HorizontalCollapse from "./HorizontalCollapse";
import HorizontalItem from "./HorizontalItem";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import routesConfig, {RouterConfigData,} from "../../../../../pages/routesConfig";
import {useAuthUser} from "../../../../utility/AuthHooks";
import {RoutePermittedRole} from "../../../../../shared/constants/AppConst";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../redux/store";

const HorizontalNav = () => {

  const { user, isAuthenticated, permissions, companyPermissions} = useAuthUser();
  const [routes, setRoutes] = useState(routesConfig)
  const [roles, setRoles] = useState([])
  const module = useSelector<AppState, AppState["common"]>(
        ({ common }) => common
    ).module;

  useEffect(() => {

      setRoles([...user.role, ...permissions, ...companyPermissions || []])

  }, [permissions, companyPermissions])

  return (
    <List className="navbarNav">
      {routes.filter(f => f.module === module).map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && (
            <HorizontalGroup roles={roles} item={item} nestedLevel={0} />
          )}

          {item.type === "collapse"  && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && (
            <HorizontalCollapse roles={roles} item={item} nestedLevel={0} />
          )}

          {item.type === "item"  && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && (
            <HorizontalItem item={item} nestedLevel={0} />
          )}

          {item.type === "divider" && <Divider sx={{ my: 5 }} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
