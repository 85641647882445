import {baseService} from './baseService';

export const sellerService = {
    listGroups,
    names
};

function listGroups() {
    return baseService.get(encodeURI('api/seller/groups'));
}

function names() {
    return baseService.get(encodeURI('api/seller/names'));
}