import React, {useEffect} from "react";
import {useLocation, useRoutes} from "react-router-dom";
import AppSuspense from "@crema/core/AppSuspense";
import AppFooter from "../AppLayout/components/AppFooter";
import AppErrorBoundary from "../AppErrorBoundary";
import Box from "@mui/material/Box";
import AppContentViewWrapper from "./AppContentViewWrapper";
import {SxProps} from "@mui/system";
import {useAuthUser} from "../../utility/AuthHooks";
import {anonymousStructure, authorizedStructure, unAuthorizedStructure,} from "../../../pages";
import generateRoutes from "../../utility/RouteGenerator";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import {Permission} from "../../../shared/constants/Permissions";
import {selectCompany, selectModule, selectSeller} from "../../../redux/actions";
import {loadCompanyState, loadSellerState, saveModuleState} from "../../../redux/store/localStorage";

interface AppContentViewProps {
    sxStyle?: SxProps;
}

const RequestList = React.lazy(() => import("../../../pages/company/request/RequestList/index"));
const Request = React.lazy(() => import("../../../pages/support/request"));

const AppContentView: React.FC<AppContentViewProps> = ({sxStyle}) => {
    const {user, isAuthenticated, permissions, isUserInRole} = useAuthUser();
    const module = useSelector<AppState, AppState["common"]>(
        ({common}) => common
    ).module;
    const location = useLocation()
    const dispatch = useDispatch();

    useEffect(() => {

        if (module === "company") {
            if ((location.pathname === "\/" && isUserInRole && isUserInRole(Permission.MODULE_SUPPORT)) || location.pathname.startsWith("\/support")) {
                dispatch(selectModule("support"));
                saveModuleState("support");
                const seller = loadSellerState();
                dispatch(selectSeller(seller))
            }
        }

        if (module === "support") {
            if ((location.pathname === "\/" && isUserInRole && !isUserInRole(Permission.MODULE_SUPPORT)) || location.pathname.startsWith("\/company")) {
                dispatch(selectModule("company"));
                saveModuleState("company");
                const company = loadCompanyState();
                dispatch(selectCompany(company?.id));
            }
        }
    }, [location]);

    const routes = useRoutes(
        generateRoutes({
            isAuthenticated: isAuthenticated,
            userRole: [...(user?.role ? user.role : []), ...(permissions?.length > 0 ? permissions : [])],
            unAuthorizedStructure,
            authorizedStructure: authorizedStructure(isUserInRole && isUserInRole(Permission.MODULE_SUPPORT) ? <Request/> : <RequestList/>),
            anonymousStructure,
        })
    );
    return (
        <AppContentViewWrapper>
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    p: {xs: 5, md: 7.5, xl: 3},
                    ...sxStyle,
                }}
                className="app-content"
            >
                <AppSuspense>
                    <AppErrorBoundary>
                        {routes}
                    </AppErrorBoundary>
                </AppSuspense>
            </Box>
            {module === "company" && false && <Fab sx={
                {
                    position: "fixed",
                    color: "white",
                    bottom: "15px",
                    right: "15px",
                }
            } color="success" size="small" aria-label="add">
                <WhatsAppIcon/>
            </Fab>}
            <AppFooter/>
        </AppContentViewWrapper>
    );
};

export default AppContentView;
