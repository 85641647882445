import React from "react";

const RequestList = React.lazy(() => import("./request"));
const RequestData = React.lazy(() => import("./request/RequestData"));
const CompanyList = React.lazy(() => import("./company/CompanyList"));
const CompanyData = React.lazy(() => import("./company/CompanyData"));
const UserSellerList = React.lazy(() => import("./user/UserSellerList"));
const UserSellerData = React.lazy(() => import("./user/UserSellerData"));
export const suportRequestPagesConfigs = [
    {
        path: [
            "/support/request/list",
        ],
        element: <RequestList/>,
    },
    {
        path: "/support/request/:id",
        element: <RequestData />,
    },
    {
        path: [
            "/support/company/list",
        ],
        element: <CompanyList/>,
    },
    {
        path: [
            "/support/company/:id",
            "/support/company",
        ],
        element: <CompanyData/>,
    },
    {
        path: [
            "/support/user/list",
        ],
        element: <UserSellerList/>,
    },
    {
        path: [
            "/support/user/:id",
            "/support/user",
        ],
        element: <UserSellerData/>,
    },
];