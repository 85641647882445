import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
    loadSellerState,
    saveCompanyState,
    savePageFilterState,
    saveSellerState
} from "../../../../../redux/store/localStorage";
import {selectCompany, selectSeller} from "../../../../../redux/actions";
import authService from "../../../../../services/authService";
import {useDispatch} from "react-redux";
import {useFirebaseActions} from "../../../../services/auth/firebase/FirebaseAuthProvider";
import {sellerService} from "../../../../../services/sellerService";
import {useNavigate} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";


const SellerModule = () => {
    const navigate = useNavigate();
    const [seller, setSeller] = useState({name: "", id: ""});
    const [sellers, setSellers] = useState([])
    const [chooseSeller, setChooseSeller] = useState(false);
    const dispatch = useDispatch();
    const {setProfileData} = useFirebaseActions();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const seller = loadSellerState();

        if (!seller) {
            showSellerDialog()
        } else {
            setSeller(seller)
            onSellerSelected()
            dispatch(selectSeller(seller.id));
        }
    }, []);

    const showSellerDialog = () => {
        setLoading(true)
        setChooseSeller(true)
        sellerService.names().then(resp => {
            setSellers(resp.data)
        }).finally(() => setLoading(false))
    }

    const handleChooseSeller = async (seller) => {
        saveSellerState(seller)
        savePageFilterState("")
        setSeller(seller)
        setChooseSeller(false);
        onSellerSelected()
        dispatch(selectSeller(seller.id));

        saveCompanyState("");
        dispatch(selectCompany(""));

        navigate("/support/request/list");
    }

    const onSellerSelected = async () => {
        const loggedUser = await authService.loggedUser().then(resp => resp.data);
        const permissions = loggedUser.permissions;
        setProfileData({
            permissions: loggedUser.permissions,
            isUserInRole: function (role: string) {
                return !!permissions && permissions.indexOf(role) >= 0
            },
        });
    }

    return (
        <>
            <Box sx={{p: 3}}>
                <Chip onClick={showSellerDialog} label={seller?.name} variant="outlined"/>
            </Box>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={chooseSeller}
                onClose={() => setChooseSeller(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    Escolha uma revenda
                </DialogTitle>
                <DialogContent>
                    <Grid container item sm={12} spacing={4} mt={3}>
                        {loading && <CircularProgress/>}
                        {!loading && sellers?.map((c, i) => <Button
                            key={`company-choose-${i}`}
                            onClick={() => handleChooseSeller(c)}
                            className="w-full m-1"
                            type="button"
                            variant="contained"
                            color="primary">{c.name}
                        </Button>)}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SellerModule;
