import React, {useEffect, useState} from "react";
import List from "@mui/material/List";

import routesConfig, {
  RouterConfigData,
} from "../../../../../pages/routesConfig";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../redux/store";
import {useAuthUser} from "../../../../utility/AuthHooks";

const VerticalNav = () => {

    const { user, isAuthenticated, permissions, companyPermissions} = useAuthUser();
    const [routes, setRoutes] = useState(routesConfig)
    const [roles, setRoles] = useState([])
    const module = useSelector<AppState, AppState["common"]>(
        ({ common }) => common
    ).module;

    useEffect(() => {

        setRoles([...user.role, ...permissions, ...companyPermissions || []])

    }, [permissions, companyPermissions])

  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div"
    >
      {routes.filter(f => f.module === module).map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && <NavVerticalGroup item={item} level={0} />}

          {item.type === "collapse" && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && (!item.permittedRole || roles.indexOf(item.permittedRole) >= 0) && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
