import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBaBrFVJEWQNYLYAqlVPUbZETGBkTa3Ioc",
  authDomain: "ezdeal-hub.firebaseapp.com",
  projectId: "ezdeal-hub",
  storageBucket: "ezdeal-hub.appspot.com",
  messagingSenderId: "106337651028",
  appId: "1:106337651028:web:c4d2fa63fcb4223d0b60c1",
  measurementId: "G-VZQJGQ8SHH"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
