export const loadCompanyState = () => {
    try {
        const serializedState = localStorage.getItem('company-state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const loadSellerState = () => {
    try {
        const serializedState = localStorage.getItem('seller-state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveCompanyState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('company-state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const saveSellerState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('seller-state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const loadCompaniesState = () => {
    try {
        const serializedState = localStorage.getItem('companies-state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveCompaniesState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('companies-state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const clearCompaniesState = () => {
    try {
        localStorage.removeItem('companies-state');
        localStorage.removeItem('company-state');
    } catch {
        // ignore write errors
    }
};

export const clearSellerState = () => {
    try {
        localStorage.removeItem('seller-state');
    } catch {
        // ignore write errors
    }
};

export const clearModuleState = () => {
    try {
        localStorage.removeItem('module-state');
    } catch {
        // ignore write errors
    }
};

export const savePageFilterState = (page, params) => {
    localStorage.setItem(page, params);
}

export const loadPageFilterState = (page) => {
    return localStorage.getItem(page);
}


export const saveModuleState = (module) => {
    localStorage.setItem('module-state', module);
}

export const loadModuleState = () => {
    return localStorage.getItem('module-state');
}
